import axios from "axios";
import NetworkUtils from "@/NetworkUtils";
import store from "../../store"

const authorize =  `Bearer ${store.getters.getToken}`;
const config = {
    headers: { Authorization: authorize }
};

export default {
    async updateItemGroups(data){
        let url = NetworkUtils.HOST+ '/items-pack/update-items';
        let response = await axios.post(url, data, config);
        return response.data;
    },
    async getItemPackByGroupIds(groupIds){
        let url = `${NetworkUtils.HOST}/items-pack/getItemPackByGroupIds`;
        let response = await axios.post(url, groupIds, config);
        return response.data;
    },

    deleteItemsPackByIds(dataPush) {
        let url = NetworkUtils.HOST+"/items-pack/deleteItemsPackByIds";
        let configPost = {
            method: 'post',
            maxBodyLength: Infinity,
            url: url,
            headers: {
                'Content-Type': 'application/json',
                Authorization: authorize
            },
            data : JSON.stringify(dataPush)
        };
        return axios.request(configPost);
    },
}