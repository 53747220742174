<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-app>
        <v-app-bar app>
            <v-card-title>
                Item Editor of Inventory
            </v-card-title>
            <v-row>
                <v-col class="pl-10 pr-5">
                    <ItemTabFilter
                            :pItems="itemBattleShortcutType"
                            :pTab="watchTabFilter"
                            @filterChange="onBattleShortcutChange"
                    >
                    </ItemTabFilter>
                </v-col>
                <v-col cols="2">
                    <v-btn
                            @click="isShowShortCutEnvironmentDash=true"
                    >
                        Environment Config
                    </v-btn>
                </v-col>
                <v-col class="pt-5">
                    <ItemCountStatusInfo :pCurrentItemCount="this.totalItem"></ItemCountStatusInfo>
                </v-col>

            </v-row>
        </v-app-bar>

        <v-main>
          <v-row no-gutters>
            <v-col  cols="1" sm="1">
              <div v-for="cat of this.categorySmallRef" :key="cat">
                <v-row no-gutters>
                  <v-card
                      class="ma-0"
                      :elevation="(computeMostVisibleCategory.key === cat) ? 2:0"
                      :color="(computeMostVisibleCategory.key === cat) ? 'blue lighten-4':'white'"
                      @click="dispatchClickSmallCat(cat)">

                   <v-card-text class="font-weight-black">
                     <v-img class="ml-1 pa-0" :src="renderCategoryIcon(cat)"
                            :max-width="25"/>

                     {{cat}}
                   </v-card-text>
                  </v-card>
                </v-row>
              </div>
            </v-col>

            <v-col>

                <ItemFilter
                        @didFilterByWord="didFilterByWord"
                >
                </ItemFilter>

                <v-row v-if="isLoading">
                    <v-progress-linear
                            color="deep-purple accent-4"
                            indeterminate
                            rounded
                            height="20"
                    ></v-progress-linear>
                </v-row>

              <v-card v-else class="overflow-y-auto" elevation="0">
                <v-virtual-scroll
                    item-height="270"
                    :height="windowHeight"
                    :items="this.optimizedList"
                    @scroll.native="onScrolling"
                >
                  <template v-slot:default="{ item }">
                      <v-row v-if="item.length === 0" no-gutters align="center" class="mt-16 pl-3">
                          <v-img class="pa-3" :src="renderCategoryIcon(item.headerTitle)"
                                 max-width="60"/>

                          <v-card-title class="font-weight-bold text-h3">
                              {{item.headerTitle}}
                          </v-card-title>
                      </v-row>
                      <v-row v-else dense no-gutters class="mt-0 pl-3">
                          <v-col
                                  cols="0" sm="0" md="0"
                                  v-for="it in item"
                                  :key="it.id"
                                  class="mx-0"
                          >
                              <ItemEditorCardItem
                                      :pItemGroupData="it"
                                      :pFilterData="getFilterData(it)"
                              >
                              </ItemEditorCardItem>

                          </v-col>
                      </v-row>
                  </template>
                </v-virtual-scroll>

              </v-card>
            </v-col>
          </v-row>

            <SmpDialog
                    :should-show="this.isShowShortCutEnvironmentDash"
                    @close="isShowShortCutEnvironmentDash=false"
                    width="1450"
            >
                <UnityServiceDashboardForPopup/>
            </SmpDialog>

        </v-main>
    </v-app>
</template>

<script>
    import ItemEditorCardItem from "./ItemEditorCardItem.vue";
    import ItemTabFilter from "./ItemTabFilter.vue";
    import ItemType from "../constants/ItemTypeConstants.js";
    import ItemColors from "../constants/ItemColorConstants.js";
    import ItemApi from "../dao/ItemApi.js"
    import {debounce} from "lodash";
    import {GlobalEvents, EVENT_ON_ITEM_SAVED} from "@/item-editor/events/GlobalEvents";
    import ItemCountStatusInfo from "@/item-editor/modules/ItemCountStatusInfo";
    import ItemCategories from "@/item-editor/constants/ItemCategories";
    import ItemFilter from "./Filter/ItemFilterComponent";
    import ItemFilterUtils from "../Utils/ItemFilterUtils";
    import HeroAndWeaponDataLoader from "@/item-editor/dao/HeroAndWeaponDataLoader";
    import UGSItemUtils from "../Utils/UGSItemUtils";
    import SMPNum from "@/SMPNum";
    import UnityServiceItemUtils from "@/unity-service-dashboard/Utils/UnityServiceItemUtils";
    import SmpDialog from "../modules/SmpDialog.vue";
    import UnityServiceDashboardForPopup from '@/unity-service-dashboard/screen/UnityServiceDashboardForPopup';

    export default {
        name: "ItemEditorList",
        components: {
            ItemCountStatusInfo,
            'ItemEditorCardItem': ItemEditorCardItem,
            'ItemTabFilter': ItemTabFilter,
            ItemFilter,
            SmpDialog,
            UnityServiceDashboardForPopup,
        },
        data() {
            return {
                itemCards: {
                    type: Array,
                    default: null
                },
                isLoading:false,
                optimizedList:null,
                itemStatus: null,
                totalItemRelease: 0,
                totalItemNew: 0,
                categoryFilter: [],
                categorySmallRef: [],
                visibleCategoryCountList: {},
                itemColorType: [],
                itemBattleShortcutType: [],
                categorySelected: [],
                colorSelected: [],
                page: 1,
                originalItems: [],
                watchFilter: '',
                watchTabFilter: '',
                windowWidth: 1920,
                windowHeight: 800,
                filterConfig: null,
                isGroupByCategory: true,
                gamePlayDataService: null,
                isShowShortCutEnvironmentDash: false,
            }
        },
        methods: {
            renderCategoryIcon(key){
              if (!key) return null;
              return require('../assets/icons/menus/' + ItemColors.menuIcons[key])
            },
            onScrolling (event) {
              const element = event.currentTarget || event.target
              const marginTopAndBottom = 750;
              if (element && element.scrollHeight - element.scrollTop <= element.clientHeight + marginTopAndBottom) {
                this.loadMoreItems();
              }

              this.refreshVisibleCategoryCountList();

            },
            resetAllFilter: function () {
                this.categorySelected = [];
                this.colorSelected = [];
                this.refreshFilterChange();
            },
            refreshFilterChange: function () {
                let toDisplay = [];
                this.itemCards = this.$store.state.itemList;

                this.itemCards.forEach(item =>{
                    toDisplay.push(item);
                });

                let isDateSorting = this.filterConfig !== null && (this.filterConfig.isSortByDate || this.filterConfig.isSortByLevel);
                toDisplay = ItemFilterUtils.getItemFilterByWord(toDisplay, this.filterConfig);

                this.optimizedList = [];
                let colCount = 0;
                let tempRow = [];
                let category = '';
                if (toDisplay.length > 0 && !isDateSorting) {
                    category = toDisplay[0].item.category;
                }
                tempRow.headerTitle = category;
                this.optimizedList.push(tempRow);
                tempRow = [];

                let maxColumn = this.getMaxColumn();

                for (let i = 0; i < toDisplay.length; i++) {
                  if (!toDisplay[i].item) continue;
                    if (toDisplay[i].item.category === category || isDateSorting) {

                        tempRow.push(toDisplay[i]);
                        colCount++;

                        //if reach col count or reach the end of display
                        if (colCount >= maxColumn || i === toDisplay.length - 1) {
                            this.optimizedList.push(tempRow);
                            tempRow = [];
                            colCount = 0;
                        }

                        //if the next item is in different category
                        let nextIndex = i + 1;
                        if (nextIndex < toDisplay.length) {
                            let item = toDisplay[nextIndex].item;
                            let categoryDis = undefined;
                            if(item === undefined){
                                categoryDis = toDisplay[nextIndex].category;
                            } else {
                                categoryDis = item.category;
                            }
                            if(categoryDis !== category && !isDateSorting){
                                this.optimizedList.push(tempRow);
                                if(item !== undefined){
                                    tempRow = [];
                                    category = categoryDis;
                                    tempRow.headerTitle = category;
                                    this.optimizedList.push(tempRow);
                                    tempRow = [];
                                    colCount = 0;
                                }
                            }
                        }
                    }
                }

                setTimeout(() => this.refreshVisibleCategoryCountList(), 200);
            },
            onCategoryChange: function (select) {
                this.categorySelected = [];
                select.forEach(s => {
                    if (s !== "accessories") {
                        this.categorySelected.push(s);
                    } else {
                        this.categorySelected.push("shield");
                        this.categorySelected.push("boots");
                    }
                });

                this.reloadItemsOnFiltering();
            },
            onCategoryReset: function () {
                if (this.categorySelected.length > 0) {
                    this.categorySelected = [];
                    this.reloadItemsOnFiltering();
                }
            },
            onColorChange: function (select) {
                this.colorSelected = [];
                select.forEach(s => {
                    this.colorSelected.push(s);
                });
                this.watchTabFilter = "all";
                this.reloadItemsOnFiltering();
            },
            onColorReset: function () {
                if (this.colorSelected.length > 0) {
                    this.colorSelected = [];
                    this.watchTabFilter = "all";
                    this.reloadItemsOnFiltering();
                }
            },
            onBattleShortcutChange: function (tab) {
                this.colorSelected = [];
                this.categorySelected = [];
                if (tab.id !== "all") {
                    let colors = ItemType.ItemBattleShortcutAndColors[tab.id];
                    colors.forEach(color => this.colorSelected.push(color));
                }
                //to change color filter on Color Filter modules
                this.watchFilter = this.colorSelected;
                this.watchTabFilter = tab;

                this.reloadItemsOnFiltering();
            },
            computeTotalItemByStatus: function () {
                this.totalItemRelease = 0;
                this.totalItemNew = 0;
                if (this.itemStatus != null) {
                    this.itemStatus.forEach(item => {
                        if (item.status === ItemType.ItemStatus.RELEASE) {
                            this.totalItemRelease++;
                        } else if (item.status === ItemType.ItemStatus.NEW) {
                            this.totalItemNew++;
                        }
                    });
                }
            },
            loadMoreItems() {
              this.page++;
              ItemApi.getItemGroups(this.page, this.colorSelected, this.categorySelected)
                  .then(data => {
                    if (data.length > 0){
                        this.applyItemRef(data);
                        this.applyEnvironmentPushHistory(data, false);
                    }
                  });
            },
            applyItemRef(groupItems){
                groupItems.forEach(group =>{
                    if(group.items === undefined){
                        if(group.item === undefined){
                            console.log("null items in group", group);
                        }
                    } else {
                        group.item = group.items[0];
                    }
                });
            },
            applyEnvironmentPushHistory(items, isFinishLoading){
                UnityServiceItemUtils.applyEnvironmentPushHistory(items, this.$store.state.ugsSetting.environmentList)
                    .then(resultItems => {
                        this.$store.commit(this.$store.state.commitActions.ADD_ITEMS, resultItems);
                        this.refreshFilterChange();
                        if(isFinishLoading){
                            this.isLoading = false;
                        }
                    });
            },
            reloadItemsOnFiltering() {
                this.isLoading = true;
                this.$store.commit(this.$store.state.commitActions.INIT_ITEMS, []);
                this.page = 1;
                ItemApi.getItemGroups(this.page, this.colorSelected, this.categorySelected)
                    .then(data => {
                        this.applyItemRef(data);
                        this.applyEnvironmentPushHistory(data, true);
                    });
            },
            refreshVisibleCategoryCountList(){
              let itemCardNodes = document.getElementsByName("item-card")
              if (itemCardNodes){
                let categoryCount = {}
                for (let i = 0; i < itemCardNodes.length; i++) {
                  let node = itemCardNodes[i]
                  let catNode = node.attributes.getNamedItem('category');
                  if (catNode){
                    let value = catNode.nodeValue
                    if (categoryCount[value]){
                      categoryCount[value] += 1;
                    }else {
                      categoryCount[value] = 1;
                    }
                  }
                }
                this.visibleCategoryCountList = categoryCount;
              }
            },
            dispatchClickSmallCat(cat){
              this.isLoading = true;
              document.getElementsByClassName("v-virtual-scroll")[0].scrollTop=0;
              this.onCategoryChange([cat])
            },
            sortedArray: function(arrays) {
                function compare(a, b) {
                    if (a < b)
                        return -1;
                    if (a > b)
                        return 1;
                    return 0;
                }

                return arrays.sort(compare);
            },

            getMaxColumn(){
                this.windowWidth = this.getWindowWidth();
                return Math.round(7 * this.windowWidth / 1920);
            },

            getWindowWidth(){
                return window.innerWidth|| document.documentElement.clientWidth||
                    document.body.clientWidth;
            },

            getWindowHeight () {
                const height = window.innerHeight|| document.documentElement.clientHeight||
                    document.body.clientHeight;
                return height-250;
            },

            onHandleWindowChangeSize(){
                this.windowHeight = this.getWindowHeight();

                let width = this.getWindowWidth();
                if(this.windowWidth !== width){
                    this.windowWidth = width;
                    this.resetAllFilter();
                }
            },

            didFilterByWord(filterConfig){
                this.filterConfig = filterConfig;
                this.resetAllFilter();
            },

            getFilterData(itemGroup){
                let levelConfig = UGSItemUtils.getItemLevelConfig(itemGroup.item.gameLevel, itemGroup.config.levelRangeSelect);
                let goldDrop = this.gamePlayDataService.getCoinDrop(levelConfig.kpiLevel).round();
                let smpGem = UGSItemUtils.getSmpGemUnit(itemGroup.config);
                let cost = SMPNum.multSmpNum(goldDrop, new SMPNum(smpGem));
                return {
                    startGameLevel: levelConfig.startLevel,
                    endGameLevel: itemGroup.item.gameLevel.levelEnd,//levelConfig.startLevel+levelConfig.levelRangePerLevel,
                    cost: cost.ToReadableAlphabetV2(),
                };
            }
        },
        computed: {
            totalItem() {
                return this.itemCards.length;
            },

            computeMostVisibleCategory(){
              let bestCat = {"value":0}
              ItemCategories.forEach(key =>{
                let value = this.visibleCategoryCountList[key];
                if (value > bestCat.value){
                  bestCat.value = value;
                  bestCat.key = key;
                }
              });
              return bestCat;
            },
        },

        async beforeCreate() {
            let refHeroAndWeaponData = await HeroAndWeaponDataLoader.loadData();
            ItemFilterUtils.init(refHeroAndWeaponData);
        },

        async created() {

            this.gamePlayDataService = this.$store.state.gamePlayDataService;

            GlobalEvents.$on(EVENT_ON_ITEM_SAVED, async () => {

              this.$store.commit(this.$store.state.commitActions.INIT_ITEMS, []);
              this.refreshFilterChange();
              this.page = 1;
              let items = await ItemApi.getItemGroups(this.page, this.colorSelected, this.categorySelected);
              if (items.length <=0 ) {
                items = await ItemApi.getItemGroups(this.page, [], []);
              }
              this.applyItemRef(items);
              items = await UnityServiceItemUtils.applyEnvironmentPushHistory(items, this.$store.state.ugsSetting.environmentList);

              this.$store.commit(this.$store.state.commitActions.INIT_ITEMS, items);
              this.refreshFilterChange();
              this.isLoading = false;

            });

            this.onScrolling = debounce(this.onScrolling, 200)
            this.reloadItemsOnFiltering = debounce(this.reloadItemsOnFiltering, 200)

            this.itemStatus = this.$store.state.itemStatusList;
            this.computeTotalItemByStatus();

            let category = ItemType.ItemCategoryType;
            this.categoryFilter = Object.keys(category);

            this.categorySmallRef = this.sortedArray(ItemCategories);

            let colorType = ItemType.ItemColorType;
            this.itemColorType = Object.keys(colorType);

            let battleShortcut = ItemType.ItemBattleShortcutType;
            let keyShortcut = Object.keys(battleShortcut);
            this.itemBattleShortcutType = [];
            this.itemBattleShortcutType.push({
                "title": "all",
                "id": "all",
                "itemIcon": null,
                "boarderIcon": null
            });

            keyShortcut.forEach(key => this.itemBattleShortcutType.push({
                "title": key,
                "id": key,
                "itemIcon": ItemColors.iconBattleShortcutIcon[key],
                "boarderIcon": ItemColors.iconBattleShortcutFrame[key]
            }));

            this.windowHeight = this.getWindowHeight();

            this.resetAllFilter();

            window.addEventListener("resize", this.onHandleWindowChangeSize);
        },

        destroyed() {
            window.removeEventListener("resize", this.onHandleWindowChangeSize);
        }
    }
</script>

<style scoped>
    .card-container {
        margin-bottom:30px;
    }

    .header-height {
      height: 150px;
    }
</style>