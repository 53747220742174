import UnityServiceApi from "@/unity-service-dashboard/api/UnityServiceApi";
import UGSVirtualPurchaseModel from "../classes/UGSVirtualPurchaseModel";
import UGSItemInventoryModel from "../classes/UGSItemInventoryModel";
import {EVENT_ON_ITEM_SAVED, GlobalEvents} from "@/item-editor/events/GlobalEvents";
import ItemPackApi from "../dao/ItemPackApi";

export default {
    initDataStore(store){
        //assign from $store
        this.store = store;
    },

    doHardDeleting(itemGroupData, itemData, onSuccess, onFailed){
        let environmentIds = itemData.pushStatus;
        let itemInventory = new UGSItemInventoryModel(itemGroupData);
        let shopItem = new UGSVirtualPurchaseModel(itemGroupData, itemInventory);
        let ugsSetting = this.store.state.ugsSetting;
        UnityServiceApi.hardDeleteItemById(itemGroupData.id, itemInventory.id, shopItem.id, environmentIds,ugsSetting.isAutoPublish)
            .then(resultItem => {
                console.log("Result : ", resultItem);
                onSuccess();
                this.removeTitleNameAndSync(itemData);
                setTimeout(() => {
                    GlobalEvents.$emit(EVENT_ON_ITEM_SAVED);
                }, 1500);
            })
            .catch(error => {
                onFailed(error);
            });
    },

    doHardDeletingItems(itemGroupDatas, onSuccess, onFailed){
        let ugsSetting = this.store.state.ugsSetting;
        let dataPush = [];
        itemGroupDatas.forEach(group => {
            let environmentIds = group.item.pushStatus;
            let itemInventory = new UGSItemInventoryModel(group);
            let shopItem = new UGSVirtualPurchaseModel(group, itemInventory);
            dataPush.push({
                groupId: group.id,
                itemId: itemInventory.id,
                shopId: shopItem.id,
                environmentIds: environmentIds
            });
        });

        UnityServiceApi.hardDeleteItemByIds(dataPush, ugsSetting.isAutoPublish, ugsSetting.environmentId)
            .then(resultItem => {
                console.log("Result : ", resultItem);
                onSuccess();
                this.removeTitleNamesAndSync(itemGroupDatas);
                setTimeout(() => {
                    GlobalEvents.$emit(EVENT_ON_ITEM_SAVED);
                }, 1500);
            })
            .catch(error => {
                onFailed(error);
            });
    },

    deleteItemsPack(itemGroupDatas, onSuccess, onFailed){
        let dataPush = [];
        itemGroupDatas.forEach(group => {
            dataPush.push({
                groupId: group.id,
            });
        });

        ItemPackApi.deleteItemsPackByIds(dataPush)
            .then(resultItem => {
                console.log("Result : ", resultItem);
                onSuccess();
                this.removeTitleNamesAndSync(itemGroupDatas);
            })
            .catch(error => {
                onFailed(error);
            });
    },

    removeTitleNameAndSync(itemData){
        let itemNameList = this.store.state.itemNameList;
        if(this.removeTitle(itemData, itemNameList)){
            this.store.commit(this.store.state.commitActions.PUSH_ITEM_NAME, itemNameList);
        }
    },

    removeTitleNamesAndSync(itemGroupDatas){
        let isFailed = false;
        let itemNameList = this.store.state.itemNameList;
        itemGroupDatas.forEach(group => {
            if(!this.removeTitle(group.item, itemNameList)){
                isFailed = true;
            }
        });
        if(isFailed){
            this.store.commit(this.store.state.commitActions.PUSH_ITEM_NAME, itemNameList);
        }
    },

    removeTitle(itemData, itemNameList){
        let titleName = itemData.title.en;
        if(titleName !== undefined && titleName !== ''){
            let refWeaponNames = this.store.state.itemWeaponNames;
            let refPotionNames = this.store.state.itemPotionNames;
            let name = itemNameList.find(itn => itn.name === titleName);
            if(name && name.usage > 0){
                name.usage--;
            }
            let wpName = refWeaponNames.find(itn => itn.name === titleName);
            if(wpName && wpName.usage > 0){
                wpName.usage--;
            }
            let psName = refPotionNames.find(itn => itn.name === titleName);
            if(psName && psName.usage > 0){
                psName.usage--;
            }
            return true;
        } else {
            return false;
        }
    }
}