import ItemModes from "../constants/ItemModes";
import {BehaviourTypeDescription} from "../constants/BehaviourTypes";

class GamePlayItem {
     constructor(original) {
          this.id = original?.id;
          this.status = original?.status ?? 'dev';
          this.equipmentCode = original?.equipmentCode ?? '';
          this.modes = original?.modes ?? [ItemModes.Battle, ItemModes.Normal];
          this.qty = original?.qty ?? 0;
          if (this.qty <= 0) this.qty = 100;
          this.category = original?.category ?? '';
          this.seller = original?.seller ?? '';
          this.color = original?.color ?? '';
          //this.availableForSale = original?.availableForSale ?? true;
          this.availableForSale = true;

          this.iconName = original?.iconName ?? '';
          this.costPerUnit = original?.costPerUnit ?? '';
          this.targetSelect = original?.targetSelect ?? [];
          this.level = original?.level ?? 1;
          let lvStart = original?.gameLevel?.levelStart ?? -1;
          this.gameLevel = {
               levelStart: lvStart,
               levelEnd: original?.gameLevel?.levelEnd ?? -1,
               kpiLevel: original?.gameLevel?.kpiLevel ?? lvStart,
          };
          this.lifeTime = {
               bean: original?.lifeTime?.bean ?? null,
               type: original?.lifeTime?.type ?? '',
               limit: original?.lifeTime?.limit ?? 0
          };
          this.title = {
               en: original?.title?.en ?? '',
               fr: original?.title?.fr ?? '',
               es: original?.title?.es ?? '',
               km: original?.title?.km ?? '',
               km_u: original?.title?.km_u ?? '',
          }
          this.description = {
               en: original?.description?.en ?? '',
               fr: original?.description?.fr ?? '',
               es: original?.description?.es ?? '',
               km: original?.description?.km ?? '',
               km_u: original?.description?.km_u ?? '',
          }

          if (original.costConfig){
               this.costConfig = {
                    kpiLevel : original?.costConfig?.kpiLevel ?? 1,
                    ratio : original?.costConfig?.ratio ?? 0.5,
                    ghostKill : original?.costConfig?.ghostKill ?? 5
               }
          }

          this.behaviours = [];
          if (original && original.behaviours){
               original.behaviours.forEach(behaviour => {
                    let beanText = '';
                    if (behaviour?.type){
                         beanText = BehaviourTypeDescription.find(beType => beType.Type === behaviour.type)?.Description;
                    }
                    this.behaviours.push({
                         behaviourBean : {
                              type: behaviour?.behaviourBean?.type ?? behaviour?.type,
                              cat: behaviour?.behaviourBean?.cat,
                              join: behaviour?.behaviourBean?.join,
                              owners: behaviour?.behaviourBean?.owners,
                              receivers: behaviour?.behaviourBean?.receivers,
                              effectSlots: behaviour?.behaviourBean?.effectSlots,
                              effectRelation: behaviour?.behaviourBean?.effectRelation,
                              des: behaviour?.behaviourBean?.des ?? {details: beanText},
                              costConfig: behaviour?.behaviourBean?.costConfig ?? original?.costConfig,

                         },
                         type: behaviour?.type ?? '',
                         gamePlayImpact: {
                              bean: behaviour?.gamePlayImpact?.bean ?? {},
                              value: behaviour?.gamePlayImpact?.value ?? null,
                              valueType: behaviour?.gamePlayImpact?.valueType ?? null,
                              periodValue: behaviour?.gamePlayImpact?.periodValue ?? null,
                              periodType: behaviour?.gamePlayImpact?.periodType ?? null,
                              skillType: behaviour?.gamePlayImpact?.skillType ?? null,
                              visualEffectOfTargets: behaviour?.gamePlayImpact?.visualEffectOfTargets ?? [],
                              receivers: behaviour?.gamePlayImpact?.receivers,
                              duration: behaviour?.gamePlayImpact?.duration ?? null,
                              dmgToPercent: behaviour?.gamePlayImpact?.dmgToPercent ?? null,
                         }
                    });
               });
          }

          this.pushStatus = [];
          if (original && original.pushStatus){
               original.pushStatus.forEach(ps =>{
                  this.pushStatus.push(ps);
               });
          }
          this.environments = original?.environments ?? [];
     }
}

export default GamePlayItem