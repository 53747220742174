<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-card elevation="0" min-width="1000" light>
        <HeaderPopup
                @save="save()"
                @close="close()"
                @reset="reset()"
                @option="showOption()"
                @bagSlotOption="bagSlotOption()"
        >
            AUTOMATE ITEMS
        </HeaderPopup>

        <v-container class="grey lighten-5">
            <v-form ref="form" class="mx-2" lazy-validation>

                <AutomateConfigDataList :automateItemCreatedData="computeAutomateConfigDatas" :categorySmallRef="categorySmallRef" />

                <v-row v-if="generateMsg">
                    <v-col cols="0">
                        <v-card-text>
                            {{generateMsg}}
                        </v-card-text>
                    </v-col>
                </v-row>

                <v-row class="mt-2 mb-2">
                    <v-col>
                        <v-btn
                                class="mr-2"
                                color="success"
                                @click="autoGenerateItem()"
                        >
                            Generate Items
                        </v-btn>
                        <v-label>
                            On Save, it will locate in Template Item Pack name {{this.$store.state.templatePack.packSelected}}
                        </v-label>
                    </v-col>
                </v-row>

                <v-card class="mt-1 mb-0 pb-0">
                    <v-row>
                        <v-col cols="2" class="ml-3">
                            Generated Result:
                        </v-col>
                        <v-col cols="4" class="pt-1">
                            <v-combobox
                                    v-model="impactFilterChosen"
                                    :items="impactSelectList"
                                    small-chips
                                    clearable
                                    label="Result by impact"
                                    prepend-icon="mdi-filter-variant"
                                    solo
                                    @change="onFilterChangeChange"
                            >
                                <template v-slot:selection="{ attrs, item, select, selected }">
                                    <v-chip
                                            v-bind="attrs"
                                            :input-value="selected"
                                            close
                                            @click="select"
                                            @click:close="removeImpactFilterChosen(item)"
                                    >
                                        <strong>{{ item }}</strong>&nbsp;
                                    </v-chip>
                                </template>
                            </v-combobox>
                        </v-col>
                    </v-row>

                    <v-virtual-scroll
                            item-height="250"
                            :height="300"
                            :items="this.optimizedList"
                    >
                        <template v-slot:default="{ item }">
                            <v-row v-if="item.length > 0" dense no-gutters class="mt-2 pl-3">
                                <v-col
                                        cols="0" sm="0" md="0"
                                        v-for="it in item"
                                        :key="it.id"
                                        class="mx-0"
                                >
                                    <AutomateCard :pItemGroupData="it.itemData" :pRenderId="it.renderId"></AutomateCard>
                                </v-col>
                            </v-row>
                        </template>
                    </v-virtual-scroll>
                </v-card>

            </v-form>
        </v-container>

        <SmpDialog
                :should-show="this.isAutomateOptionShow"
                @close="automateOptionClose()"
                width="550"
        >
            <AutomateOptionPopup
                    :pAutomateConfig="this.automateCreateItemOptions"
                    @applyConfig="defineAutomateItemConfig()"
                    @resetCounter="reset()"
            />
        </SmpDialog>

        <SmpDialog
                :should-show="isBagSlotOptionShow"
                @close="isBagSlotOptionShow=false"
                width="550"
        >
            <BagSlotOptionPopup
                    :pBagSlotConfig="this.bagSlotConfig"
            />
        </SmpDialog>

        <v-overlay :value="isLoading">
            <v-progress-circular
                    indeterminate
                    size="64"
            ></v-progress-circular>
        </v-overlay>
        <v-snackbar
                v-model="snackbar"
                :timeout="timeoutSnackbar"
                :color="colorSnackbar"
                centered
        >
            {{ textSnackbar }}

            <template v-slot:action="{ attrs }">
                <v-btn
                        color="blue"
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-card>
</template>

<script>
    import HeaderPopup from "./../components/AutomateHeaderPopupComponent.vue";
    import BehaviourJoinTargetOwnerList from "@/item-editor/constants/data-rules/BehaviourJoinTargetOwnerList";
    import AutomateCard from "../components/AutomateCard";
    import ItemIconFileLoader from "@/item-editor/dao/ItemIconsFileLoader";
    import Weapon3DPackType, {Weapon3DPackTypeName, PackTypeRemoved} from "@/item-editor/constants/Weapon3DPackType";
    import WeaponAndTypeLoader from "@/item-editor/dao/WeaponAndTypeLoader";
    // import ItemApi from "../../../dao/ItemApi.js"
    import {EVENT_ON_ITEM_SAVED, GlobalEvents, EVENT_UPDATED_AUTOMATE_ITEM_FORM} from "@/item-editor/events/GlobalEvents";
    import LocalizeUnicodeApi from "@/item-editor/dao/LocalizeUnicodeApi";
    import SmpDialog from "./../../SmpDialog";
    //import UGSItemInventoryModel from "../../../classes/UGSItemInventoryModel";
    import AutomateItemCreatedModel from "./../class/AutomateItemCreatedModel";
    import AutomateBagSlotCreatedModel from "./../class/AutomateBagSlotCreatedModel";
    import AutomateConfigDataList from "./../components/AutomateConfigDataList";
    import AutomateItemConfigUtils from "../../../Utils/AutomateItemConfigUtils";
    import ItemDeleteUtils from "../../../Utils/ItemDeleteUtils";
    import UnityServiceItemUtils from "@/unity-service-dashboard/Utils/UnityServiceItemUtils";
    import ItemUGSPushUtils from "../../../Utils/ItemUGSPushUtils";
    import AutomateItemCreateDataLoader from "./../../../dao/AutomateItemCreateDataLoader";
    import AutomateOptionPopup from "./../components/AutomateOptionPopup";
    //import LocalStorageUtils from "@/kpi-editor/Utils/LocalStorageUtils";
    import BagSlotOptionPopup from "../components/BagSlotOptionPopup";
    import {BehaviourByNames} from "@/item-editor/constants/BehaviourTypes";
    import AutomateItemGeneratorUtils from "../../../Utils/AutomateItemGeneratorUtils";
    const MAX_ITEM_LIMIT = 377;//377 x 2 = 754 (item + shop). UGS inventory limit is 755 but we have SMPGem count as one ready
    import ItemPackApi from "../../../dao/ItemPackApi.js"

    export default {
        name: "AutomateItem",
        components: {
            BagSlotOptionPopup,
            HeaderPopup,
            AutomateCard,
            SmpDialog,
            AutomateConfigDataList,
            AutomateOptionPopup
        },
        data(){
            return {
                isDisableUGSPush: true,
                isLoading:false,
                snackbar: false,
                textSnackbar: '',
                colorSnackbar: 'success',
                timeoutSnackbar: 2000,
                generateMsg:'',
                impactSelectList: [],
                automateItems: [],
                optimizedList: [],
                totalIconList:[],
                categorySmallRef: [],
                refWeaponNames: [],
                refPotionNames: [],
                itemNameList: [],
                automateItemCreatedData: [],
                automateCreateItemOptions: {},
                automateCreateItemUtils: null,
                impactFilterChosen: "damage_enemy",
                groupAutomateItems: [],
                groupItemIdsWillRemove:[],
                updateItemCount: 0,
                isAutomateOptionShow: false,
                isBagSlotOptionShow: false,
                bagSlotConfig: {},
                automateItemGeneratorUtils: null,
            }
        },
        methods:{
            save(){
                let successful = this.$refs.form.validate();
                if (successful && this.generateMsg === ''){
                    this.saveAutomateNewItem();
                }
            },
            saveAutomateNewItem() {
                this.isLoading = true;
                if(this.automateItems.length > 0){
                    this.updateItemCount = this.automateItems.length;
                    for(let index = 0;index < this.automateItems.length;index++){
                        this.updateItemGroups(this.automateItems[index]);
                        this.updateItemNameUsage(this.automateItems[index].item);
                    }
                    this.$store.commit(this.$store.state.commitActions.PUSH_ITEM_NAME, this.itemNameList);
                } else if(this.deleteGroupItemByGroupIds()){
                    this.updateAutomateConfigGroupIds();
                    this.$store.commit(this.$store.state.commitActions.PUSH_ITEM_NAME, this.itemNameList);
                } else {
                    this.snackbar = true;
                    this.textSnackbar = "Failed to submit automate item";
                    this.colorSnackbar = 'red';

                    setTimeout(() => {
                        this.isLoading = false;
                    }, 200);
                }
            },
            saveAutomateConfig(){
                let packSelected = this.$store.state.templatePack.packSelected;
                let dataPush = {
                    list: this.automateItemCreatedData,
                    simulatorOptions: this.automateCreateItemOptions
                };
                AutomateItemCreateDataLoader.pushAutomateItemCreateData(dataPush, packSelected);
            },
            updateItemGroups(itemGroup) {
                //let environmentIds = itemGroup.item.pushStatus;
                let dataPush = {
                    backEndData: itemGroup,
                    /*in items pack no Economy Sync
                    economyData: {
                        environmentIds: environmentIds,
                        itemEconomy: environmentIds === undefined || environmentIds.length === 0 ? undefined : new UGSItemInventoryModel(itemGroup)
                    },*/
                };

                let descrForLimon = itemGroup.items[0].title.km_u+'ចំណុចកាត់ត'+itemGroup.items[0].description.km_u;
                LocalizeUnicodeApi.getLimonFromUnicode(descrForLimon).then(resultLimon => {
                    let splt = resultLimon.split('cMNuckat;t');//ចំណុចកាត់ត
                    itemGroup.items[0].title.km = splt[0];
                    itemGroup.items[0].description.km = splt[1];
                    ItemPackApi.updateItemGroups(dataPush)
                        .then(result => {
                            console.log("Result : ", result);
                            itemGroup.id = result.id;
                            itemGroup.item.id = result.itemId;
                            this.onUpdateItemComplete();
                        })
                        .catch(error => {
                            this.snackbar = true;
                            console.log('error updateItemGroups', error);
                            this.textSnackbar = "Failed to create a new item: "+ error;
                            this.colorSnackbar = 'red';

                            setTimeout(() => {
                                this.isLoading = false;
                            }, 1500);
                        });
                    setTimeout(() => {
                    }, 1500);
                }).catch(error => {
                    this.snackbar = true;
                    this.textSnackbar = "Failed to create a new item: " + error;
                    this.colorSnackbar = 'red';
                    setTimeout(() => {
                    }, 100)});
            },
            onUpdateItemComplete(){
                this.updateItemCount --;
                if(this.updateItemCount <= 0){
                    setTimeout(() => {
                        this.updateAutomateConfigGroupIds();
                        this.pushAutomateUGS();
                    }, 200);
                }
            },
            updateItemNameUsage(itemData){
                let titleName = itemData.title.en;
                let name = this.itemNameList.find(itn => itn.name === titleName);
                if(name){
                    name.usage++;
                }
                let wpName = this.refWeaponNames.find(itn => itn.name === titleName);
                if(wpName){
                    wpName.usage++;
                }
                let psName = this.refPotionNames.find(itn => itn.name === titleName);
                if(psName){
                    psName.usage++;
                }
            },
            updateItemNameUnUsage(itemData){
                let titleName = itemData.title.en;
                let name = this.itemNameList.find(itn => itn.name === titleName);
                if(name && name.usage > 0){
                    name.usage--;
                }
                let wpName = this.refWeaponNames.find(itn => itn.name === titleName);
                if(wpName && wpName.usage > 0){
                    wpName.usage--;
                }
                let psName = this.refPotionNames.find(itn => itn.name === titleName);
                if(psName && psName.usage > 0){
                    psName.usage--;
                }
            },
            deleteGroupItemByGroupIds(){
                if(this.groupItemIdsWillRemove.length > 0){
                    let callBackSuccess =()=>{
                        this.textSnackbar = "NoneUseItem was deleted!";
                        this.colorSnackbar = 'success';
                        setTimeout(() => {
                            this.isLoading = false;
                            this.close();
                        }, 1500);
                    };
                    let callBackFailed = (error)=>{
                        this.textSnackbar = "Failed to delete NoneUseItem: " + error;
                        this.colorSnackbar = 'red';

                        setTimeout(() => {
                            this.isLoading = false;
                        }, 200);
                    };
                    this.isLoading = true;
                    this.groupItemIdsWillRemove.forEach(group => {
                        this.updateItemNameUnUsage(group.item);
                    });
                    ItemDeleteUtils.deleteItemsPack(this.groupItemIdsWillRemove, callBackSuccess, callBackFailed);
                    this.groupItemIdsWillRemove = [];
                    this.automateItemGeneratorUtils.clearGroupItemRefs();
                    return true;
                } else{
                    return false;
                }
            },
            updateAutomateConfigGroupIds(){
                this.groupAutomateItems.forEach(group => {
                    let conf = this.automateItemCreatedData.find(d => d.impactType === group.impactType);
                    conf.groupsIds = [];
                    group.items.forEach(g => conf.groupsIds.push(g.id));
                });
                this.saveAutomateConfig();
            },
            pushAutomateUGS(){
                let callBackSuccess =()=>{
                    setTimeout(() => {
                        if(!this.deleteGroupItemByGroupIds()){
                            this.snackbar = true;
                            this.textSnackbar = "New item was created!";
                            this.colorSnackbar = 'success';
                            GlobalEvents.$emit(EVENT_ON_ITEM_SAVED);
                            setTimeout(() => {
                                this.isLoading = false;
                                this.close();
                            }, 1500);
                        }
                    }, 1500);
                };
                let callBackFailed = (error)=>{
                    this.snackbar = true;
                    this.textSnackbar = "Failed to create a new item: " + error;
                    this.colorSnackbar = 'red';

                    setTimeout(() => {
                        this.isLoading = false;
                    }, 1500);
                };
                this.isLoading = true;
                if(this.isDisableUGSPush){
                    callBackSuccess();
                    console.log('Not push items to UGS');
                } else {
                    ItemUGSPushUtils.doPushingItems(this.automateItems, callBackSuccess, callBackFailed);
                }
            },
            loadAutomateItemFromConfigRef(){
                let allGroupIds = [];
                this.automateItemCreatedData.forEach(config => {
                    config.groupsIds.forEach(id => allGroupIds.push(id));
                });

                if(allGroupIds.length > 0){
                    this.isLoading = true;
                    ItemPackApi.getItemPackByGroupIds(allGroupIds)
                        .then(result => {
                            this.applyGroupResultToAutomateList(result);
                            UnityServiceItemUtils.applyEnvironmentPushHistory(result, this.$store.state.ugsSetting.environmentList)
                                .then(resultItems => {
                                    console.log('rs apply status', resultItems);
                                    this.snackbar = true;
                                    this.textSnackbar = "Get items from old config success";
                                    this.colorSnackbar = 'success';

                                    this.onFilterChangeChange();

                                    setTimeout(() => {
                                        this.isLoading = false;
                                    }, 1500);
                                }).catch(error => {
                                this.snackbar = true;
                                this.textSnackbar = "Failed to load item from old config: " + error;
                                this.colorSnackbar = 'red';

                                setTimeout(() => {
                                    this.isLoading = false;
                                }, 1500);
                            });
                        })
                        .catch(error => {
                            this.snackbar = true;
                            this.textSnackbar = "Failed to load item from old config: " + error;
                            this.colorSnackbar = 'red';

                            setTimeout(() => {
                                this.isLoading = false;
                            }, 1500);
                        });
                    setTimeout(() => {
                    }, 1500);
                }
            },

            close(){
                this.$emit('close');
            },

            automateOptionClose(){
               this.isAutomateOptionShow = false;

                //save to local storage
                //LocalStorageUtils.saveObject("automateCreateItemOptions", this.$store.state.automateCreateItemOptions);
            },

            showOption(){
                this.isAutomateOptionShow = true;
            },

            bagSlotOption(){
                this.isBagSlotOptionShow = true;
            },

            isBagSlotConfig(){
                return this.config.impactType === BehaviourByNames.incBagSlot;
            },

            reset(){
                this.automateOptionClose();
                
                this.automateItemCreatedData.forEach(item => {
                    if(item.impactType !== BehaviourByNames.incBagSlot){
                        item.itemRootCount = 0;
                    }
                });

                //msg
                this.snackbar = true;
                this.textSnackbar = "Item root counter was reset!!!";
                this.colorSnackbar = 'green';

                setTimeout(() => {
                    this.isLoading = false;
                }, 1000);
            },

            autoGenerateItem() {
                if(this.isPassLimit()){
                    this.isLoading = true;
                    this.clearItems();

                    //to call automate generator
                    this.automateItemGeneratorUtils.prepareGenerateItems();
                    this.automateItemGeneratorUtils.startGenerateAllImpactType();
                    this.toWaitAutomateFinish();

                } else {
                    this.snackbar = true;
                    this.textSnackbar = "Automate item will over UGS Limit " + MAX_ITEM_LIMIT;
                    this.colorSnackbar = 'red';

                    setTimeout(() => {
                        this.isLoading = false;
                    }, 1500);
                }
            },

            toWaitAutomateFinish(){
                if(this.automateItemGeneratorUtils.isLoading){
                    setTimeout(() => {
                        this.toWaitAutomateFinish();
                    }, 100);
                } else {
                    this.setGenerateMsg(this.automateItemGeneratorUtils.generateMsg);
                    this.automateItems = this.automateItemGeneratorUtils.automateItems;
                    this.groupItemIdsWillRemove = this.automateItemGeneratorUtils.groupItemIdsWillRemove;
                    this.isLoading = false;
                    this.onFilterChangeChange();
                }
            },

            isPassLimit(){
                let count = 0;
                for(let index = 0;index<this.automateItemCreatedData.length;index++){
                    let rootCount = parseInt(this.automateItemCreatedData[index].itemRootCount);
                    count += rootCount;
                    this.automateItemCreatedData[index].itemRootCount = rootCount;
                }
                return count <= MAX_ITEM_LIMIT;
            },

            clearItems(){
                this.setGenerateMsg('');
                this.optimizedList = [];
                this.automateItems = [];
            },

            generateItemListView(items) {
                let toDisplay = [];
                items.forEach(item =>{
                    toDisplay.push(item);
                });

                let maxColumn = this.getMaxColumn();
                this.optimizedList = [];
                let colCount = 0;
                let tempRow = [];
                for (let i = 0; i < toDisplay.length; i++) {
                    if (!toDisplay[i].item) continue;
                    tempRow.push({
                        itemData: toDisplay[i],
                        renderId: 'renderId-'+i,
                    });
                    colCount++;

                    //if reach col count or reach the end of display
                    if (colCount === maxColumn || i === toDisplay.length - 1) {
                        this.optimizedList.push(tempRow);
                        tempRow = [];
                        colCount = 0;
                    }
                }
            },

            setGenerateMsg(msg){
                this.generateMsg = msg;
            },

            rulesNotEmptyAndHigherThan (fieldName, min) {
                return [
                    v => (!!v || fieldName + ' is required!') && (v > min || 'Invalid')
                ];
            },
            rulesNotEmptyAndBetween (fieldName, min, max) {
                return [
                    v => (!!v || fieldName + ' is required!') && (v >= min && v <= max || 'Invalid')
                ];
            },
            rulesNotEmpty (fieldName) {
                return [
                    v => !!v || fieldName + ' is required!'
                ];
            },

            onUpdatedAutomateItemForm(itemGroupData, renderId){
                let isUpdate = false;
                this.optimizedList.forEach(optItem => {
                    optItem.forEach(it => {
                        if(it.renderId === renderId){
                            this.applyEditItem(it.itemData, itemGroupData);
                            isUpdate = true;
                        }
                    });
                });
                this.isLoading = true;
                if(isUpdate){
                    this.optimizedList = [];
                    this.snackbar = true;
                    this.textSnackbar = 'Updated item '+renderId;
                    this.colorSnackbar = 'green';
                    setTimeout(() => {
                        this.generateItemListView(this.automateItems);
                        this.isLoading = false;
                    }, 500);
                } else{
                    this.snackbar = true;
                    this.textSnackbar = 'invalid on update item '+renderId;
                    this.colorSnackbar = 'red';
                    setTimeout(() => {
                        this.isLoading = false;
                    }, 1200);
                }
            },
            applyEditItem(itemSource, itemEdited){
                for (let key in itemEdited) {
                    itemSource[key] = itemEdited[key];
                }
            },

            initAutomateConfig(){
                this.automateCreateItemOptions = this.$store.state.automateCreateItemOptions;
                this.automateCreateItemUtils = new AutomateItemConfigUtils(this.$store.state.simulatorItemRequireList, this.automateCreateItemOptions);

                this.automateItemCreatedData = this.$store.state.automateItemCreatedData;
                if(this.automateItemCreatedData.length === 0){
                    this.impactSelectList.forEach(impact =>{
                        let config = new AutomateItemCreatedModel();
                        config.impactType = impact;
                        config.itemRootCount = 0;
                        this.automateItemCreatedData.push(config);
                    });
                }
                let bagSlot = this.automateItemCreatedData.find(c => c.impactType === BehaviourByNames.incBagSlot);
                if(bagSlot === undefined){
                    bagSlot = new AutomateBagSlotCreatedModel();
                    this.automateItemCreatedData.push(bagSlot);
                }
                this.bagSlotConfig = bagSlot;

                this.automateItemCreatedData.forEach(config =>{
                   if(config.categoriesSelected === undefined || config.categoriesSelected.length === 0){
                       if(config.categoriesSelected === undefined){
                           config.categoriesSelected = [];
                       }
                       if(config.categorySelected !== undefined && config.categorySelected !== ""){
                           config.categoriesSelected.push(config.categorySelected);
                       } else {
                           config.categoriesSelected.push("ALL");
                       }
                   }
                });
            },

            initGroupAutomateItems(){
                this.automateItemCreatedData.forEach(config => {
                    this.groupAutomateItems.push({
                        impactType: config.impactType,
                        items: [],
                    });
                });
            },

            defineAutomateItemConfig(){

                this.automateOptionClose();

                let simulData = this.$store.state.simulatorItemRequireList;
                if(simulData.length === 0){
                    //msg
                    this.snackbar = true;
                    this.textSnackbar = "no simulator data, plz go to kpi editor dashboard and run SIMULATOR MARKET ITEM REQUIRE";
                    this.colorSnackbar = 'red';

                    setTimeout(() => {
                        this.isLoading = false;
                    }, 3000);
                } else {
                    this.automateItemCreatedData.forEach(item => {
                        this.automateCreateItemUtils.applyConfig(item);
                    });

                    //msg
                    this.snackbar = true;
                    this.textSnackbar = "Config has applied";
                    this.colorSnackbar = 'green';

                    setTimeout(() => {
                        this.isLoading = false;
                    }, 1500);
                }
            },

            applyGroupResultToAutomateList(result){
                result.forEach(group => {
                    let config = this.automateItemCreatedData.find(data => data.groupsIds.includes(group.id));
                    let automateItem = this.groupAutomateItems.find(g => g.impactType === config.impactType);
                    group.item = group.items[0];
                    automateItem.items.push(group);
                    this.automateItems.push(group);
                });
            },

            onFilterChangeChange(){
                this.optimizedList = [];
                this.isLoading = true;
                setTimeout(() => {
                    this.isLoading = false;
                    let allItems = [];
                    if(this.impactFilterChosen === null || this.impactFilterChosen === ''){
                        this.groupAutomateItems.forEach(g => {
                            g.items.forEach(it => allItems.push(it));
                        });
                    } else {
                        allItems = this.groupAutomateItems.find(g => g.impactType === this.impactFilterChosen).items;
                    }
                    this.generateItemListView(allItems);
                }, 100);
            },
            removeImpactFilterChosen (item) {
                if(this.impactFilterChosen === item){
                    this.impactFilterChosen = '';
                }
                this.onFilterChangeChange();
            },

            getMaxColumn(){
                return Math.round(5 * this.getWindowWidth() / 1920);
            },

            getWindowWidth(){
                return window.innerWidth|| document.documentElement.clientWidth||
                    document.body.clientWidth;
            },
        },
        computed:{
            computeAutomateConfigDatas(){
                if(this.automateItemCreatedData === undefined
                    || this.automateItemCreatedData.length === 0) {
                    return [];
                }

                return this.automateItemCreatedData.filter(conf => conf.impactType !== BehaviourByNames.incBagSlot);
            },
            computeWindowHeight() {
                const height = window.innerHeight || document.documentElement.clientHeight ||
                    document.body.clientHeight;
                return height * 0.4;
            },
        },
        created() {
            this.impactSelectList = [];
            BehaviourJoinTargetOwnerList.forEach(ref => {
                if(!this.impactSelectList.includes(ref.type)) {
                    this.impactSelectList.push(ref.type);
                }
            });

            ItemDeleteUtils.initDataStore(this.$store);
            ItemUGSPushUtils.initDataStore(this.$store);

            // let options = LocalStorageUtils.getObject("automateCreateItemOptions");
            // if(options !== undefined){
            //     this.$store.state.automateCreateItemOptions = options;
            // }

            this.initAutomateConfig();
            this.initGroupAutomateItems();
            this.loadAutomateItemFromConfigRef();

            //move automate to generator utils
            let gamePlayDataService = this.$store.state.gamePlayDataService;
            this.refWeaponNames = this.$store.state.itemWeaponNames;
            this.refPotionNames = this.$store.state.itemPotionNames;
            this.automateItemGeneratorUtils = new AutomateItemGeneratorUtils(this.automateItemCreatedData,
                                                            this.groupAutomateItems,
                                                            gamePlayDataService,
                                                            this.refWeaponNames,
                                                            this.refPotionNames);
            this.automateItemGeneratorUtils.loadData();

            GlobalEvents.$on(EVENT_UPDATED_AUTOMATE_ITEM_FORM, (itemGroupData, renderId) => this.onUpdatedAutomateItemForm(itemGroupData, renderId));
        },
        async beforeCreate() {
            this.totalIconList = await ItemIconFileLoader.loadIconRefData();
            let refWeaponAndTypeData = await WeaponAndTypeLoader.loadData();
            this.itemNameList = this.$store.state.itemNameList;
            this.totalIconList.forEach(icon => {
                let weaponData = refWeaponAndTypeData.find(r => {
                    return r.weaponName+".png" === icon.name;
                });
                if(weaponData) {
                    icon.categoryRefType = Weapon3DPackType[weaponData.weapon3DPackType];
                    icon.category = Weapon3DPackTypeName[icon.categoryRefType];
                }
            });

            this.categorySmallRef = [];
            this.categorySmallRef.push('ALL');
            Weapon3DPackType.forEach(ref =>{
                if(!PackTypeRemoved.includes(ref)){
                    this.categorySmallRef.push(ref);
                }
            });
        },
    }
</script>

<style scoped>

</style>