import UGSItemUtils from "../Utils/UGSItemUtils";

export default {
    init(refHeroAndWeaponData){
        this.refHeroAndWeaponData = refHeroAndWeaponData;
    },

    getItemFilterByWord(items, filterConfig){
        let results = items;
        let isCategorySort = true;
        let isLevelAppearSort = false;
        if(filterConfig !== null){
            const {itemFilterTypeWord, itemFilterChosen, isSortByDate, isSortByLevel, heroIdFilter, impactFilterChosen, levelFilter, environmentId} = filterConfig;
            isCategorySort = !isSortByDate && !isSortByLevel;
            isLevelAppearSort = isSortByLevel;
            results = results.filter((itemGroup) => {
                let item = itemGroup.item;

                //filter by environmentId
                if(environmentId && environmentId !== ''){
                    if(environmentId === 'NONE_PUSH_STATUS'){
                        if(item.pushStatus && item.pushStatus.length >= 0){
                            return false;
                        }
                    } else {
                        let passFilter = item.pushStatus && item.pushStatus.includes(environmentId);
                        if(!passFilter){
                            return false;
                        }
                    }
                }

                //filter by level
                item.levelConfig = UGSItemUtils.getItemLevelConfig(item.gameLevel, itemGroup.config.levelRangeSelect);
                if(levelFilter > 0){
                    let levelConfig = item.levelConfig;
                    let passFilter = levelFilter >= levelConfig.startLevel && levelFilter < levelConfig.endLevel;
                    if(!passFilter){
                        return false;
                    }
                }

                //filter by hero id
                if(heroIdFilter > 0){
                    let passFilter = this.supportedHeroes(heroIdFilter, item.equipmentCode);
                    if(!passFilter){
                        return false;
                    }
                }

                //filter by impact
                if(impactFilterChosen && impactFilterChosen !== ''){
                    let passFilter = true;
                    item.behaviours.forEach(behaviour  =>{
                        passFilter &= behaviour.type === impactFilterChosen;
                    });

                    if(!passFilter){
                        return false;
                    }
                }

                //filter by tag
                if(itemFilterChosen.length > 0){
                    let passFilter = true;
                    item.behaviours.forEach(behaviour  =>{
                        let bean = behaviour.behaviourBean;
                        for (const id in itemFilterChosen) {
                            let item = itemFilterChosen[id];
                            passFilter &= bean.des.details.toUpperCase().includes(item.toUpperCase()) ||
                                bean.des.title.toUpperCase().includes(item.toUpperCase()) ||
                                bean.join.toUpperCase().includes(item.toUpperCase()) ||
                                bean.cat.toUpperCase().includes(item.toUpperCase());
                        }
                    });

                    if(!passFilter){
                        return false;
                    }
                }

                //filter by search
                if(itemFilterTypeWord) {
                    let wUpercase = itemFilterTypeWord.toUpperCase();
                    return item.title.en.toUpperCase().includes(wUpercase)
                        || item.title.fr.toUpperCase().includes(wUpercase)
                        || item.description.en.toUpperCase().includes(wUpercase)
                        || item.description.fr.toUpperCase().includes(wUpercase);
                } else {
                    return true;
                }
            });
        }

        if(isLevelAppearSort){
            //to sort by increasing
            results = results.sort((a, b) => (a.item.levelConfig.startLevel < b.item.levelConfig.startLevel) ? -1 : 1);
        } else {
            //default to sort by descending
            results = results.sort((a, b) => (new Date(a.item.createdAt).getTime() < new Date(b.item.createdAt).getTime()) ? 1 : -1);
        }

        if(isCategorySort){
            results = results.sort(function(a, b){
                if (a.item.category < b.item.category) {return -1;}
                if (a.item.category > b.item.category) {return 1;}
                return 0;
            });
        }

        return results;
    },

    supportedHeroes (heroId, equipmentCode) {
        if (equipmentCode !== ''){
            let refHero = this.refHeroAndWeaponData.find(ref => ref.heroId === heroId);
            let code = parseInt(equipmentCode);
            if (refHero.weaponIds.includes(code)){
                return true;
            }
            return false;
        }else {
            return true;
        }
    },
}