<template>
    <v-toolbar
            color="indigo"
            dark
            dense fixed>
        <v-card-title>
            <slot></slot>
        </v-card-title>
        <v-btn
                v-if="pCanSave"
                class="ma-2"
                color="success"
                @click="save"
        >
            <v-icon
                    left
            >
                mdi-content-save
            </v-icon>
            Save the item
        </v-btn>

        <v-btn
                color="cancel"
                @click="close()"
        >
            Cancel
        </v-btn>

        <v-btn
                v-if="pShowSibling"
                class="ma-10"
                color="orange"
                @click="sibling()"
        >
            Sibling
        </v-btn>

        <v-btn
                v-if="pPushing"
                class="ma-2"
                color="blue"
                @click="pushing()"
        >
            {{getPushingText()}}
        </v-btn>

        <v-btn
                v-if="pDeleting"
                class="ma-2"
                color="red"
                @click="deleting()"
        >
            {{getDeletingText()}}
        </v-btn>

        <v-btn
                v-if="pHardDeleting"
                class="ma-2"
                color="red darken-2"
                @click="hardDeleting()"
        >
            Hard Delete
        </v-btn>

        <v-spacer></v-spacer>
    </v-toolbar>
</template>

<script>
    export default {
        name: "HeaderPopupComponent.vue",
        props: {
            'pShowSibling':{
                type: Boolean,
                default: false,
                required: false
            },
            'pCanSave':{
                type: Boolean,
                default: true,
                required: false
            },
            'pPushing':{
                type: Boolean,
                default: false,
                required: false
            },
            'pDeleting':{
                type: Boolean,
                default: false,
                required: false
            },
            'pHardDeleting':{
                type: Boolean,
                default: false,
                required: false
            },
        },
        methods: {
            close: function () {
                this.$emit('close');
            },
            save: function () {
                this.$emit('save');
            },
            sibling: function () {
                this.$emit('sibling');
            },
            pushing: function () {
                this.$emit('pushing');
            },
            getPushingText: function () {
                let txt = "Push";
                if(this.$store.state.ugsSetting){
                    txt += ' to '+this.$store.state.ugsSetting.environmentName;
                }
                return txt;
            },

            deleting: function (){
                this.$emit('deleting');
            },
            hardDeleting: function (){
                if(confirm("Do you want to Delete item, will remove this clone item and remove Unity Economy item every Environment?")){
                    this.$emit('hardDeleting');
                }
            },
            getDeletingText: function () {
                let txt = "Delete";
                if(this.$store.state.ugsSetting){
                    txt += ' from '+this.$store.state.ugsSetting.environmentName;
                }
                return txt;
            }
        },
    }
</script>

<style scoped>

</style>